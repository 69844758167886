import * as prismicClient from '@prismicio/client';
import { cardsAdapter } from './adapter';
import { stripRichTextWrapperTag } from '@/utils/html';
import {
  routerLinks,
  offerLinks,
  countryLinks,
  regionLinks,
} from '@/utils/fetch-links';

const getItems = async (taxonomyIds, prismic, lang) => {
  const query = [prismicClient.filter.at('document.type', 'page_guide')];
  query.push(
    prismicClient.filter.any(`my.page_guide.taxonomies.taxonomy`, taxonomyIds),
  );

  const items = await prismic.get({
    filters: query,
    orderings: [
      {
        field: 'my.page_guide.date',
        direction: 'desc',
      },
      {
        field: 'document.last_publication_date',
        direction: 'desc',
      },
    ],
    pageSize: 4,
    fetchLinks: [
      ...routerLinks,
      ...offerLinks,
      ...countryLinks,
      ...regionLinks,
    ],
    lang,
  });

  return items.results.map((result) => ({
    edito_carousel_card: { ...result, link_type: 'Document' },
  }));
};

export default async (
  { primary, items },
  { i18n, prismic, enhancedLinkSerializer },
) => {
  const lang = i18n.localeProperties.value.language;
  const taxonomyIds = items.map((item) => item.taxonomy.id);

  const cardItems = await getItems(taxonomyIds, prismic, lang);

  return {
    featured: primary.edito_carousel_featured,
    title: stripRichTextWrapperTag(primary.edito_carousel_title),
    link: enhancedLinkSerializer(primary.edito_carousel_link),
    linkLabel: primary.edito_carousel_link_label,
    linkType: primary.edito_carousel_lik_type,
    withCarousel: primary.edito_carousel_active,
    cards: cardsAdapter(cardItems, i18n, enhancedLinkSerializer),
  };
};
